import React from "react";

export default function HeroTemplate12() {
  return (
    <div className="mx-auto max-w-5xl md:px-4 sm:px-6 lg:px-8 ">
       <a target="_blank" href="https://www.youtube.com/watch?v=2nAGhV1k30s">
     
       <img
                    className="object-cover w-full h-full md:block"
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1724062511/hero/q4qoaogkqf9qmjphnazn.jpg"
                    alt="SWA Hero Image"
                  />
      </a>
      <a target="_blank" href="https://forms.office.com/r/ntzexLb7Ea">
        <p className="max-w-2xl mx-auto mt-3  sm:mt-4 p-3 rounded-lg bg-white bg-opacity-70 md:bg-opacity-20 text-center text-lg font-bold">
          Apply now! Application open till 18 October 2024. Click here for more information.
        </p>
      </a>
    </div>
  );
}

{/* <div className="mx-auto max-w-5xl md:px-4 sm:px-6 lg:px-8 ">
<a target="_blank" href="https://www.youtube.com/watch?v=2nAGhV1k30s">

<video
 className="object-cover w-full h-full rounded-2xl block md:block "
 controls
 autoPlay
 muted
 loop
 playsInline
>
 <source
   src="https://res.cloudinary.com/dkm14ge3g/video/upload/v1720538782/hero/banner_dfb6nr.mp4"
   type="video/mp4"
 />
 Your browser does not support the video tag.
</video>
</a>
<a target="_blank" href="/sp?utm_campaign=charitydinner&utm_source=hero">
 <p className="max-w-2xl mx-auto mt-3 text-lg text-gray-600 sm:mt-4 p-3 rounded-lg bg-white bg-opacity-70 md:bg-opacity-20 text-center">
   We're excited to celebrate the 70th Anniversary of the Singapore
   Women's Association. Click here for more information
 </p>
</a>
</div> */}